@import "./config.scss";

footer .modulo{
    color: map-get($colors,three); 
    font-family: map-get($fonts,three);
    margin: 0 0 20px 0;
    display: table;
    width: 100%;
}
footer .modulo .modulo-titulo{
    margin: 0 0 25px 0;
}
footer h3{
    font-size: 18px;
    color: map-get($colors,thirty-seven);  
    font-family: map-get($fonts,ten) !important;
    margin: 0;
    text-transform: uppercase;
}
footer h4{
    font-size: 18px;
    font-family: map-get($fonts,seven);
    color: #FFF;
}
footer .modulo .modulo-content{
    font-size: 16px;
}
footer .modulo .linha{
    background: map-get($colors,four);
    width: 68px;
    height: 4px;
    margin: 20px 0 30px;
}

@media (max-width: 959px){

    footer h3{
        font-size: 20px;
    }

}
@media (max-width: 767px){

    footer .modulo h3{
        font-size: 26px;
    }
    footer .modulo{
        margin: 0 0 20px 0 !important;
    }

}