@import "config.scss";

.page{
    padding: 60px 0 100px 0;
}
.page .page-title{
    position: relative;
    display: table;
    width: 100%;
    text-align: left;
}
.page .page-title h1,
.page .page-title h2{
    margin: 0;
}
.page .page-title .linha{
    width: 100%;
    height: 1px;
    display: table; 
    margin: 30px 0;
    background: map-get($colors,twenty-one);
}
.page .page-title h1{
    font-size: 30px;
    font-family: map-get($fonts,eight);
    color: map-get($colors,twenty); 
} 
.page .page-title h2{
    font-size: 14px;
    font-family: map-get($fonts,eight);
    color: #000;
    font-weight: normal;
    margin: 2px 0 0 0;
}
.page .descricao article{
    color: map-get($colors,twenty-two);
    text-align: justify;
}
.page .page-title .linha-left{
    left: 0;
}
.page .page-title .linha-right{
    right: 0;
}
/** SOBRE **/
.page-sobre .descricao article h3{
    color: #222222;
    font-family: map-get($fonts,twenty-two);
    font-size: 18px;
    margin: 40px 0;
}

@media(max-width: 959px){

    .page{
        padding-top: 100px !important;
    }

}
@media(max-width: 767px){

    .page .page-title .linha{
        width: 25%;
        top: 15px;
    }
    .page .page-title h1{
        font-size: 20px !important;
    }
    .page .page-title h2{
        font-size: 16px !important;
    }

}