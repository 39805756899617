@import "./config.scss";
@import "material.scss";
@import "./btn.scss";
@import "datepicker.scss";
@import "./form.scss";
@import "modais.scss";
@import "icons.scss";

body{
    overflow-x: hidden;
    font-family: map-get($fonts,one); 
    font-weight: normal !important;
    color: map-get($colors,forty-five);  
}
figure{
    margin: 0;
}
ul{
    margin:0;
    padding:0;
    list-style: none;
}
.ul-inline li{
    display: table;
    float: left;
}
a{
    text-decoration: none !important;
}
.center{
    margin: 0 auto;
    display: table;
}
h1{
    font-family: map-get($fonts,two);
}
h2,h3{
    font-family: map-get($fonts,one);
}
.section-bloco-padding{
    padding: 100px 0;
}
.section-bloco .modulo-title h2{
    margin: 0;
    font-size: 70px;
    text-transform: uppercase;
    line-height: 65px;
}
.section-bloco .modulo-title .linha{
    height: 4px;
    width: 57px;
    display: table;
}
.section-bloco .modulo-title{
    margin: 0 0 40px 0;
}
.linha-divisor-title{
    background: map-get($colors,seventeen);
    height: 6px;
    width: 60px;
    display: table;
    margin: 8px auto;
}
.pswp__caption__center small{
    display: none;
}
.hide{
    display: none;
}
/** BTN **/
.btn-custom{
    border: 1px solid #FFF;
    color: #FFF;
    font-family: map-get($fonts,three);
    border-radius: 5px;
    padding: 7px 30px;
    text-shadow: 1px 1px 1px #353535;
    -webkit-box-shadow: 0 2px 7px 0 #333333;
    box-shadow: 0 2px 7px 0 #333333;
    cursor: pointer;
}
.modulo-fotos-bustour .descricao p{
    margin: 0;
}
/** Input Custom **/
.input-custom{
	border: 0;
	border-bottom: 1px solid map-get($colors,blue);
	padding: 5px 0;
	font-size: 14px;
	width: 100%;
	outline: none;
	margin: 0 0 10px 0;
}
.form-control:focus{
    box-shadow: none !important;
}
/** PAGE SOBRE **/
.page-sobre .content-texto article span{
    position: relative;
    top: 7px;
}
/** ALERT MESSAGE **/
.alert-message{
    width: 100%;
    display: table;
    font-size: 12px;
    border-radius: 0;
}
.modal-body p{
  margin: 0;
}
.modal-body .modal-passageiro{
    margin: 0 0 10px 0;
}
.modal-body .modal-passageiro h6{
    font-size: 14px;
    text-transform: uppercase;
}
.alert-inline{
    font-size: 12px;
    border-radius: 0; 
}
/** HEADER FIXED **/
header.fixed .container-logo{
    padding: 5px 0 0 0;
}
header.fixed .menu li a span{
    font-size: 12px;
}
.bg-grey{
    background: #fbfafa;
}

@media(max-width: 480px){

  
    .page-pagamento-atividade-sucesso .content-links ul li{
        width: 100%;
    }   
    .page-pagamento-atividade-sucesso .content-links ul li a{
        width: 100% !important;
        display: table;
        text-align: center;
    } 


}