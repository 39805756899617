@import "config.scss";

.dm-form input{
    outline: none !important;
    border: 0;
    border-bottom: 1px solid map-get($colors,twenty-six);
    width: 100%;
    font-size: 14px;
    padding: 5px 0;
    font-family: map-get($fonts,six);
}
.dm-form .dm-form-item{
    margin: 0 0 10px 0;
}
.dm-form.dm-form-input-2 input{
    border: 1px solid map-get($colors,one);
    padding: 10px 10px;
    font-size: 12px;
    margin: 0 0 10px 0;
}
.dm-form input::-moz-input-placeholder{
    color: map-get($colors,one);
}
.dm-form input::-moz-input-placeholder{
    color: map-get($colors,one);
}
.dm-form input::-webkit-input-placeholder{
    color: map-get($colors,one);
}
.dm-form input::placeholder{
    color: map-get($colors,one);
}
.dm-form.dm-form-input-2 input::placeholder{
    color: map-get($colors,twenty-eight);
}
.dm-form.dm-form-input-2 input::-webkit-input-placeholder{
    color: map-get($colors,twenty-eight);
}
.dm-form.dm-form-input-2 input::-moz-input-placeholder{
    color: map-get($colors,twenty-eight);
}
.dm-form.dm-form-placeholder-bold input::placeholder{
    font-weight: bold;
}
.dm-form .dm-form-item{
    margin: 0 0 5px 0;
}
/** DM CHECKBOX **/
.dm-check-box{
    display: table;
    margin: 0 0 5px 0;
}
.dm-check-box-input{
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: table;
    border: 2px solid #eee;
    background: #FFF;
    margin: 0 10px 0 0;
    cursor: pointer;
}
.dm-check-box-input.active{
    background: map-get($colors,eight);
}
.dm-check-box-input,
.dm-check-box-value{
    display: table;
    float: left;
}
.dm-check-box-value{
    text-transform: uppercase;
    position: relative;
    top: -2px;
}
.dm-check-box-info{
    font-size: 10px;
    color: map-get($colors,one);
    top: -2px;
}
/** MODAL DIALOG **/
.modal-dialog .message-text{
    padding: 10px;
}
/** FORM CUSTOM **/
.form-custom label{
    float: left;
    position: relative;
    width: 100%;
    text-align: left;
    font-weight: 500;
    color: #878C9F;
    font-size: 13px;
    font-weight: 500;
}
.form-custom label i{
    padding-right: 12px;
    font-size: 14px;
    position: absolute;
    top: 16px;
    left: 16px;
    color: map-get($colors,thirty-two);
}
.form-custom input[type="text"],
.form-custom input[type="password"],
.form-custom input[type="file"],
.form-custom textarea,
.form-custom mat-select{
    float: left;
    border: none;
    border: 1px solid #eee;
    border-radius: 6px;
    background: #F7F9FB;
    width: 100%;
    padding: 13px 20px 13px 20px;
    border-radius: 6px;
    color: map-get($colors,one);
    font-size: 13px;
    -webkit-appearance: none;
    outline: none !important;
    margin: 0 0 10px 0;
}
.form-custom textarea{
    resize: none;
    padding: 21px 17px;
    -webkit-appearance: none;
}
.form-custom mat-select{
    padding: 8px !important;
}
.form-custom mat-select .mat-select-value-text{
    position: relative;
    top: 5px;
    font-size: 12px;
}