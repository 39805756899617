/** Galeria Template **/
.parada-galeria-template{
    display: none;
}
.parada-galeria-template .parada-galeria{
    position: fixed;
    z-index: 1000;
}
.parada-galeria-template .parada-galeria ul{
    padding: 0;
    z-index: 2;
    position: relative;
}
.parada-galeria-template .parada-galeria li{
    display: none;
}
.parada-galeria-template .parada-galeria li.show{
    display: block;
}
.parada-galeria-template .parada-galeria .content{
    position: fixed;
    top: 10%;
    z-index: 2000;
    display: table;
    margin: 0 auto;
    left: 20%;
}
.parada-galeria-template .parada-galeria .content .content-images{
    position: relative;
    padding: 20px;
}
.parada-galeria-template .parada-galeria .content .bg{
    background: #1c1c1b;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -moz-opacity: 0.80;
    -khtml-opacity: 0.80;
    opacity: 0.90;
    z-index: 1;
}
.parada-galeria-template .parada-galeria .overlay{
    background: #000;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    -moz-opacity: 0.80;
    -khtml-opacity: 0.80;
    opacity: 0.80;
    
}
.parada-galeria-template .parada-galeria li .legenda{
    display: table;
    width: 100%;
    margin: 15px 0 0 0;
}
.parada-galeria-template .parada-galeria li h5{
    font-size: 20px;
    color: #FFF;
    display: table;
    margin: 0;
}
.parada-galeria-template .parada-galeria .close-galeria{
    position: absolute;
    z-index: 10;
    right: 20px;
    cursor: pointer;
}
.parada-galeria-template .parada-galeria .close-galeria i,
.parada-galeria-template .prev i,
.parada-galeria-template .next i{
    color: #FFF;
    font-size: 50px;
    text-shadow: 1px 2px 1px #000;
}
.parada-galeria-template .prev,
.parada-galeria-template .next{
    position: absolute;
    z-index: 10;
    top: 35%;
    cursor: pointer;
}
.parada-galeria-template .prev i,
.parada-galeria-template .next i{
    font-size: 120px;
}
.parada-galeria-template .prev{
    left: 0px;
}
.parada-galeria-template .next{
    right: 0px;
}

@media only screen and (min-width: 960px) and (max-width: 1199px){

    .parada-galeria-template .parada-galeria .content{
        width: 60%;
    }

}
@media (min-width: 768px) and (max-width: 959px){

    .parada-galeria-template .parada-galeria .content{
        width: 90%;
        left: 5%;
    }

}
@media (max-width: 767px){

    .parada-galeria-template .parada-galeria .content{
        width: 100%;
        left: 0;
    }

}