@font-face {
    font-family: 'Roboto Light';
    src: url('/assets/fonts/Roboto-Light.woff2') format('woff2'),
        url('/assets/fonts/Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Regular';
    src: url('/assets/fonts/Roboto-Regular.woff2') format('woff2'),
        url('/assets/fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Black';
    src: url('/assets/fonts/Roboto-Black.woff2') format('woff2'),
        url('/assets/fonts/Roboto-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Thin';
    src: url('/assets/fonts/Roboto-Thin.woff2') format('woff2'),
        url('/assets/fonts/Roboto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Medium';
    src: url('/assets/fonts/Roboto-Medium.woff2') format('woff2'),
        url('/assets/fonts/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Bold';
    src: url('/assets/fonts/Roboto-Bold.woff2') format('woff2'),
        url('/assets/fonts/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway Regular';
    src: url('/assets/fonts/Raleway-Regular.woff2') format('woff2'),
        url('/assets/fonts/Raleway-Regular.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway Light';
    src: url('/assets/fonts/Raleway-Light.woff2') format('woff2'),
        url('/assets/fonts/Raleway-Light.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway SemiBold';
    src: url('/assets/fonts/Raleway-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/Raleway-SemiBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway ExtraBold';
    src: url('/assets/fonts/Raleway-ExtraBold.woff2') format('woff2'),
        url('/assets/fonts/Raleway-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway ExtraLight';
    src: url('/assets/fonts/Raleway-ExtraLight.woff2') format('woff2'),
        url('/assets/fonts/Raleway-ExtraLight.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway Black';
    src: url('/assets/fonts/Raleway-Black.woff2') format('woff2'),
        url('/assets/fonts/Raleway-Black.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway Bold';
    src: url('/assets/fonts/Raleway-Bold.woff2') format('woff2'),
        url('/assets/fonts/Raleway-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

$fonts : (
    one: "Roboto Light",
    two: "Roboto Regular",
    three: "Roboto Thin",
    four: "Roboto Medium",
    five: "Roboto Black",
    six: "Roboto Bold",
    seven: "Raleway Regular",
    eight: "Raleway Light",
    nine: "Raleway SemiBold",
    ten: "Raleway Bold",
    eleven: "Raleway ExtraBold",
    twelve: "Raleway ExtraLight",
    thirteen: "Raleway Black" 
);
/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/material.woff2') format('woff2');
}

$colors: (
    one: #024e23,    
    two: #f8a82a,
    three: #ffa500, 
    four: #bababa, 
    five: #004c1e,
    six: #f9f9f9, 
    seven: #1d1d1d, 
    eight: #00a242, 
    nine: #ffa800, 
    ten: #f8a523,
    eleven: #787878, 
    twelve: #ffce7e, 
    thirteen: #505050, 
    fourteen: #107a3e, 
    fifteen: #292929,
    sixteen: #d5deea,
    seventeen: #fcfcfc,
    eighteen: #e7e7e7,
    nineteen: #9c9c9c,
    twenty: #002f10,
    twenty-one: #e5eae7,
    twenty-two: #a7a7a7, 
    twenty-three: #efefef,
    twenty-four: #006801,
    twenty-five: #eeeeee,
    twenty-six: #0a5c2c,
    twenty-seven: #2e2e2e,
    twenty-eight: #cfcfcf,
    twenty-nine: #296d45,
    thirty: #999999,
    thirty-one: #067938,
    thirty-two:#ffba4d,
    thirty-three: #f3f3f3, 
    thirty-four: #f3f3f3,
    thirty-five: #003245,
    thirty-six: #525252,
    thirty-seven: #53585c, 
    thirty-eight: #dcdcdc,
    thirty-nine: #00b64f,
    forty: #007b4b,
    forty-one: #878C9F,
    forty-two: #898989,
    forty-three: #018b55,
    forty-four: #00a263,
    forty-five: #767676,
    forty-six: #9A999E,
    forty-seven: #5a5a5a, 
    forty-eigth: #dfdfdf,
    forty-nine: #f8f8f8,
    fifty: #e94f2d,
    fifty-one: #fef3df,
    fifty-two: #d5b58a,
    fifty-three: #38b6ab,
    fifty-four: #b2b2b2,
    fifty-five: #3e3e3e,
    fifty-six: #dc3545,
    fifty-seven: #f9f9f9,
    fifty-eight: #70c57e,
    fifty-nine: #f3f3f3,
    sixty: #222  
);
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
.degrade-01{
    background: #47291a; 
    background: -moz-linear-gradient(top, #47291a 0%, #5b3421 60%, #673b26 100%); 
    background: -webkit-linear-gradient(top, #47291a 0%,#5b3421 60%,#673b26 100%); 
    background: linear-gradient(to bottom, #47291a 0%,#5b3421 60%,#673b26 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#47291a', endColorstr='#673b26',GradientType=0 ); 
}
