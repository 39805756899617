@import "config.scss";

.btn-one,.btn-two,.btn-three{
    font-size: 14px; 
    border: 0;
    padding: 7px 20px;
    outline: none !important;
    color: #fff;  
    text-decoration: none;
    display: table;
    -webkit-box-shadow: 0 0 1px 0 #505050;
    box-shadow: 0 0 1px 0 #505050;
}
.btn-ten,.btn-ten:hover,.btn-eleven,.btn-eleven:hover,.btn-twelve,.btn-twelve:hover{
    border-radius: 0;
    border: 0;
    padding: 10px 40px;
    outline: none !important;
    color: #FFF;
    font-weight: bold;
}
.btn-twelve,.btn-twelve:hover{
    color: #353535;
    background: map-get($colors,forty-eigth);
    padding: 10px 20px;
}
.btn-one{ 
    background: map-get($colors,one); 
    font-size: 12px;
    color: #FFF;
    border-radius: 10px;
}
.btn-two{
    font-size: 10px;
    float: left;
    color: map-get($colors,one) !important;
    padding: 10px 35px;
    border: 1px solid map-get($colors,two);
    background: map-get($colors,thirty-two);
}
.btn-three{ 
    background: map-get($colors,one);
    border-top: 1px solid map-get($colors,one); 
    color: #FFF !important;
    font-size: 12px;
    color: #FFF !important;
}
.btn-ten{ 
    background: map-get($colors,forty-seven);
}
.btn-eleven{ 
    background: map-get($colors,seventeen);
    color: map-get($colors,eighteen);
}
.btn-icon i{
    font-size: 20px !important;
    position: relative;
    top: 3px;
}
.btn-icon span{
    position: relative;
    top: -2px;
}
button.btn-one,
button.btn-two{
    padding: 10px 20px !important;
}
.btn-icon-img img{
    position: relative;
    width: 15px;
    margin: 0 5px 0 0;
}
.btn-icon-img span{
    position: relative;
    top: 1px !important;
}
.btn-custom-two{
    background: map-get($colors,forty-four);
    border-radius: 5px;
    border: 0;
    color: map-get($colors,one);
    padding: 7px 20px;
    outline: none !important;
    color: #FFF;
    font-size: 12px;
    font-family: map-get($fonts,seven);
    text-decoration: none;
    border: 0;
}
.btn-custom-three{
    background: map-get($colors,thirty-three);
    border: 0;
    border-radius: 10px;
    color: map-get($colors,one);
    padding: 7px 20px;
    font-size: 14px;
    outline: none !important;
    color: #000;
    font-size: 12px;
    font-family: map-get($fonts,seven);
    text-decoration: none;
}
.btn-custom-two:hover{
    color: #FFF !important;
}
.btn-custom-two i{ 
    font-size: 14px !important;
}
.btn-custom-three i,.btn-custom-four i,.btn-custom-five i{
    margin: 0 5px 0 0;
}
.btn-custom-three{
    background: #8dc63f;
    color: #FFF !important;
    box-shadow: 0px 0px 0px 4px rgba(255,255,255,0.2);
    padding: 11px 18px;
    font-size: 13px;
    border-radius: 4px;
    text-decoration: none;
    outline: none !important;
    font-family: map-get($fonts,three);
    border: 0;
    cursor: pointer !important;
    position: relative;
}
.i-arrow i{
    position: absolute;
    right: 0;
    width: 50px;
    height: 100%;
    border-left: 1px solid rgba(255,255,255,0.21);
    text-align: center;
    top: 0;
}
.i-arrow i:before{
    position: absolute;
    top: 35%;
}
.i-arrow:after{
    content: '';
    position: absolute;
    width: 50px;
    height: 100%;
    background: rgba(255,255,255,0.2);
    z-index: 0;
    right: 0;
    top: 0;
    transition: all 200ms linear;
}
.i-arrow:after{
    opacity: 0.2;
}
.btn-custom-four{
    background: map-get($colors,one);
    color: #FFF !important;
    box-shadow: 0px 0px 0px 4px rgba(255,255,255,0.2);
    padding: 11px 18px;
    font-size: 13px;
    border-radius: 4px;
    text-decoration: none;
    outline: none !important;
    font-family: map-get($fonts,three);
    border: 0;
    cursor: pointer !important;
    position: relative;
}
.btn-custom-five{
    background: map-get($colors,eight);
    color: #FFF !important;
    box-shadow: 0px 0px 0px 4px rgba(255,255,255,0.2);
    padding: 11px 18px;
    font-size: 13px;
    border-radius: 4px;
    text-decoration: none;
    outline: none !important;
    font-family: map-get($fonts,three);
    border: 0;
    cursor: pointer !important;
    position: relative;
}