@import "./config.scss";

.modal-dialog,
.mat-dialog-container{
    padding: 25px;
}
.mat-modal-content{
    background: #FFF;
    padding: 15px;
}
.modal .modal-header,
.mat-modal-header{
    padding: 5px 0;
    border-bottom: 1px solid #eee;
}
.modal .modal-header h5,
.mat-modal-header h5{
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    color: map-get($colors,one);
    display: table;
    margin: 0 auto;
}
.modal .modal-footer{
    border: 0;
    padding: 0;
    margin: 0 0 10px 0;
}
.modal-content{
    border-radius: 0 !important;
    padding: 10px;
}
.modal .modal-body,
.mat-modal-content .mat-dialog-content{
    background: map-get($colors,fifty-seven);
    margin: 20px 0;
    font-size: 14px;
}
.mat-modal-content .mat-dialog-content{
    padding: 10px;
}
.modal .modal-footer .modal-footer-content,
.mat-modal-content .mat-dialog-actions .mat-dialog-actions-content{
    display: table;
    margin: 0 auto;
}
.modal .modal-footer button,
.mat-modal-content .mat-dialog-actions button{
    border-radius: 15px;
    background: #FFF;
    border: 1px solid map-get($colors,one);
    color: map-get($colors,one) !important;   
    float: left;
    margin: 0 5px 0 0;
    font-size: 11px;
    padding: 10px 30px !important;
}
.mat-modal-content .mat-dialog-actions{
    display: table !important;
    padding: 0 !important;
    width: 100%;
}
.mat-modal-content .mat-dialog-actions:last-child{
    margin-bottom: 0 !important;
}
.mat-modal-content .mat-dialog-actions button{
    padding: 10px 30px !important;
}